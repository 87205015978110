.button {
    width: 100px;
    height: 50px;
    color: white;
    background-color: var(--geeks-secondary);
    border: none;
    border-radius: 5px;
    font-size: 8px;
    transition: transform 0.5s ease;
  }
  
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: scale(1.0);
  }
  
  .rectangle {
    width: 100px;
    transform: scale(2.0);
  }
  