
/*Prof Summary AI Card on mobile*/
@media (max-width: 576px) {
    .ai-suggestion-card { 
        position: absolute; 
        top: 520px; 
        left: 0px; 
        z-index: 99999 ; 
        width: 100%;
        height: 400px !important;
        border-radius: 3px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
        transform-origin: 0px 0px;
        transition-property: opacity, transform;
        transition-timing-function: ease-out;
        transition-duration: 0.15s;
        transform: translateX(0px) translateY(-50%) translateZ(0px);
        opacity: 1;    
    }    
}

/*Prof Summary AI Card on desktop*/
@media (min-width: 577px) {
.ai-suggestion-card { 
    position: absolute; 
    top: 200px; 
    right: -570px; 
    z-index: 99999 ; 
    width: 560px;
    height: 450px !important;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
    transform-origin: 0px 0px;
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
    transition-duration: 0.15s;
    transform: translateX(0px) translateY(-50%) translateZ(0px);
    opacity: 1;    
}
}


/*Employment Details AI Card on mobile*/
@media (max-width: 576px) {
    .employment-ai-suggestion-card { 
        position: absolute; 
        z-index: 99999 ; 
        width: 100%;
        height: 400px !important;
        border-radius: 3px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
        transform-origin: 0px 0px;
        transition-property: opacity, transform;
        transition-timing-function: ease-out;
        transition-duration: 0.15s;
        transform: translateX(0px) translateY(-50%) translateZ(0px);
        opacity: 1;    
    }    
}

/*Employment Details Card on desktop*/
@media (min-width: 577px) {
.employment-ai-suggestion-card { 
    position: absolute; 
    z-index: 99999 ; 
    width: 560px;
    height: 450px !important;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
 
}
}


.suggestionLine{
    cursor: pointer;
}
.suggestionLine:hover{
      background-color: var(--geeks-gray-200)
  }