:root {
  --gray: #6c757d;
}

.file-dropzone {
  height: 500px;
  border: 2px dashed var(--gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.image-wrapper {
  height: 500px;
  background-color: var(--dark);
  position: relative;
}
