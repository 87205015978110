.ribbonStyle {
  position: absolute;
  top: -8px;
  left: -8px;
  overflow: hidden;
  /* background-color: #ff0000; */
  color: white;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  z-index: 0;
}

.ribbonStyle::before {
  content: "Top Mentor";
  position: absolute;
  width: 150%;
  height: 24px;
  background: #ff7e1c;
  transform: rotate(-45deg) translateY(-20px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.1em;
  z-index: 1;
}

.ribbonStyle::after {
  content: "";
  position: absolute;
  bottom: 11px;
  left: -0.2em;
  width: 10px;
  height: 10px;
  background: #c75f10;
  z-index: -1;
  box-shadow: 81px -81px #c75f10;
}
