.widgets-wrapper{
    z-index: 99;
    position: fixed;
    bottom: 0px;
    right: 45px;
    width: 80px;
    background-color: transparent;
    padding: 15px;
  }
  .widgets{
    width: 60px;
    width: intrinsic;
    height: 60px;
    margin: 0 0 10px 0;
    font-size: 25px;
    font-size: intrinsic;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    display: flex;
    text-align: center;
    /*background-color: #2c3895;*/
    /*background-color: #128c7e;*/
    /*background-color: #52cd5f;*/  
    background-color: #4aeb67;
    color: #fff;
  }
  .widget-body{
    width: 500px;
    background-color: #ececff;
    position: absolute;
    bottom: 30%;
    right: 0%;
    font-size: 20px;
    /* padding: 25px; */
    border-radius: 15px;
  }
  .widget-body-call, .widget-body-chat, .widget-body-email{
    padding: 25px;
  }
  .widget-body-header{
    padding: 20px;
    font-weight: bold;
    color: #fff;
    border-radius: 15px 15px 0 0;
    background-color: rgb(76, 76, 253);
  }