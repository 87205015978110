.navbar-nav .nav-link {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    color: #0b0909!important;
    text-decoration: none;    
    height: 2.5rem;
    line-height: 1.4;    
  }
  
  .navbar-nav:hover .nav-link:hover
  {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #3658a1 !important;
    /*border-bottom:3px solid #3658a1;*/
  }

  .navbar .dropdown .dropdown-toggle:after {
    color: white;
  }

  .navbar-vertical .navbar-nav .nav-link {
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    color: #ede9e9!important;
    text-decoration: none;    
    height: 2.5rem;
    line-height: 1.4;    
  }

  .navbar-vertical .navbar-nav:hover .nav-link:hover
  {
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #3658a1 !important;
    /*border-bottom:3px solid #3658a1;*/
  }