
.drag-handle {
  cursor: grab;
  position: absolute; /* Keeps precise positioning */
  top: -25px; /* Spacing from the top */
  right: -29px; /* Spacing from the right */
  display: grid; /* Use grid layout for rows and columns */
  grid-template-columns: repeat(2, auto); /* Create three columns */
  grid-gap: 3px; /* Add space between dots */
  justify-content: center;
  align-items: center;
}

.drag-handle .dot {
  height: 4px;
  width: 4px;
  background-color: #333; /* Visibility */
  border-radius: 50%;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 140px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;

  /* Positioning */
  position: absolute;
  top: -30px; /* Move tooltip above */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000; /* Above other elements */
}

/* Show tooltip on hover */
.drag-handle:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Button Styling */
.btn-add {
  /* background-color: #c2c9d0; */
  color: #0ea5e9;
  padding-left:1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between icon and text */
  cursor: pointer;
  font-weight:bold;
}

/* Loading state - with pulse animation */
.btn-add.loading {
  background-color: #3f8de0; /* Darker blue when loading */
  animation: pulse 1.5s infinite; /* Pulsing effect */
  
}

/* Hover and Active states */
.btn-add:hover {
  /*background-color: #0056b3;  Darker on hover */
  transform: scale(1.05); /* Slightly enlarge the button */
}

.btn-add:active {
  /*background-color: #004085;  Even darker on click */
  transform: scale(0.98); /* Slight shrink when clicked */
}

/* Disabled state */
.btn-add:disabled {
  background-color: #d6d6d6; /* Light gray when disabled */
  cursor: not-allowed;
}

/* Pulse animation effect */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* CircularProgress styles */
.MuiCircularProgress-root {
  color: white !important; /* Ensure the spinner color is white */
}

