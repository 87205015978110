.resume-builder {
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  background-color: #fff;
}

.getStartedbtn :hover {
  background-color: #3644a1 !important;
}

:root {
  --danger: #dc3545;
}

:root {
  --warning: #ffc107;
}

:root {
  --success: #28a745;
}

/* .resume-builder .row:not(.no-gutters) {
  margin: 0 -15px;
} */
.resume-builder .row.no-gutters {
  margin: 0;
}

.resume-builder input[type="date"] {
  position: relative;
}

.resume-builder input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
}

.resume-builder__progress {
  margin: 2rem 0;
}

.resume-builder__progress .progress {
  height: 0.25rem;
  margin-top: 0.5rem;
}

.resume-builder__progress .progress .progress-bar {
  margin: 0;
}

.resume-builder__progress__now {
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 0.25rem;
}

.resume-builder__progress__now--danger {
  color: var(--danger);
}

.resume-builder__progress__now--warning {
  color: var(--warning);
}

.resume-builder__progress__now--success {
  color: var(--success);
}

.resume-builder__section {
  margin-bottom: 2rem;
}

.resume-builder__section {
  margin-bottom: 2rem;
}

.resume-builder__section__card {
  padding: 1rem;
  border: 1px solid rgb(230 235 244);
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  position: relative;
}

.resume-builder__section__card--title {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.resume-builder__section__card--delete {
  position: absolute;
  right: -35px;
  top: 16px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.resume-builder__section__card:hover .resume-builder__section__card--delete {
  opacity: 1;
}

.resume-builder__img-modal .modal-content,
.resume-builder__img-modal .modal-content .modal-body {
  width: 100% !important;
}

.resume-builder__img-modal .modal-content .modal-body {
  padding: 0;
}

.resume-builder .MuiSnackbarContent-message {
  margin: 0;
}

.resume-builder__template {
  display: flex;
  justify-content: center;
  margin: 0;
  background-color: #7a8599;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

/* .hideTemplate {
    visibility: hidden;
  } */

@media screen and (max-width: 767px) {
  .resume-builder__template {
    height: 300px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* .resume-builder__form {
      padding-top: 300px;
    } */
}

.hideTemplate {
  display: none;
}

.showTemplateInFullView {
  padding-top: 4rem;
  height: 100vh;
  width: 100vw;
}

.react-pdf__Page__canvas {
  height: 836px !important;
}

/*Mobile*/
@media screen and (max-width: 576px) {
  .resume__pagination {
    display: flex;
    justify-content: center;
    bottom: 25px;
    position: absolute;
    width: 100%;
    left: 0;
  }
}

/*Desktop*/
@media screen and (min-width: 577px) {
  .resume__pagination {
    display: flex;
    justify-content: center;
    bottom: 10px;
    position: absolute;
    width: 100%;
    left: 0;
  }
}

.resume__pagination__item {
  color: rgb(233, 233, 237);


}

.resume-builder__back {
  position: absolute;
  top: 5px;
  background: rgba(255, 255, 255, 0.5);
  right: 5px;
  border-radius: 50%;
  padding: 10px;
  line-height: 1;
  color: #fff;
}

/*Mobile*/
/* @media screen and (max-width: 576px) {
.resume-builder_select_template {
  position: absolute;
  bottom: 25px;
  right: 220px;
  padding: 5px;
  line-height: 1;
  color:#fff;

}
} */
/*Desktop*/
/* @media screen and (min-width: 577px) {
  .resume-builder_select_template {
    position: absolute;
    bottom: 650px;
    right:450px;
    padding: 5px;
    line-height: 1;
    color: #fff;
    border:1px solid #fff; 
  }
  } */

.SelectTemplate:hover {
  transform: scale(1.3);
  padding: 5px;
}

/* Base styles for the button */
.resume-builder_select_template {
  position: fixed;
  bottom: 630px;
  right: 460px;
  padding: 5px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid rgb(237, 233, 233);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, font-size 0.3s ease, padding 0.3s ease;
  border-radius: 10px;
  height: 30px;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.resume-builder_select_template:hover {
  border: 1px solid #6b7488;
  background-color: #656e83;
}


/* Responsive adjustments for extra-large desktops */
@media (min-width: 1200px) {
  .resume-builder_select_template {
    font-size: 13px;
    padding: 12px;
    position: fixed;
    bottom: 93%;
    right: 28%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(237, 233, 233);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, font-size 0.3s ease, padding 0.3s ease;
    border-radius: 10px;
    height: 30px;
    font-weight: bold;
    letter-spacing: 0.8px;
  }
}

/* Responsive adjustments for large desktops */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .resume-builder_select_template {
    font-size: 13px;
    padding: 11px;
    position: fixed;
    bottom: 93%;
    right: 28%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(237, 233, 233);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, font-size 0.3s ease, padding 0.3s ease;
    border-radius: 10px;
    height: 30px;
    font-weight: bold;
    letter-spacing: 0.8px;
  }
}

/* Responsive adjustments for tablets */
@media (min-width: 768px) and (max-width: 991.98px) {
  .resume-builder_select_template {
    font-size: 13px;
    padding: 10px;
    position: fixed;
    bottom: 96%;
    right: 32%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, font-size 0.3s ease, padding 0.3s ease;
    border-radius: 10px;
    height: 28px;
    font-weight: bold;
    letter-spacing: 0.8px;
  }
}

/* Responsive adjustments for mobile sizes */
@media (max-width: 767.98px) {
  .resume-builder_select_template {
    position: fixed;
    font-size: 13px;
    padding: 9px;
    top: 2.4vh;
    right: 220px;
    padding: 5px;
    line-height: 1;
    border: 1px solid rgb(237, 233, 233);
    color: #fff;
    font-weight: bold;
    z-Index: 1000;
    letter-spacing: 0.8px;
  }
}
.upload:hover{
  background: linear-gradient(to right, lightblue 20%, #FAFAFA 20%);
}
