.makeCenter {
  margin-left: 65px;
}
.makeRound {
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.2);
}
.biggerFont {
  font-size: 20px;
}

.filter-button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding-bottom: 10px;
  height: inherit;
}

.filter-button.active {
  text-decoration: none;
  border: none;
  border-bottom: 1px solid #001289;
  background-color: transparent;
  color: #001289;
  padding-bottom: 10px;
}
.button-text {
  margin-bottom: 15px;
  padding: 10px;
}

.slick-next,
.slick-prev {
  display: none;
}

.filter-options-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.filter-button {
  white-space: nowrap;
  min-width: 100px;
}

.filter-options-container::-webkit-scrollbar {
  display: none;
}

.mobileresumebuilderimg {
  width: 530px;
  height: 750px;
}
.buildYourResumeBlock {
  width: auto;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 26px 0 30px 30px;
  border-radius: 24px;
  box-shadow: 32px 36px 100px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.upperSection {
  height: 700px;
  background-image: linear-gradient(0deg, #3644a1, #001289);
  font-family: "noto sans", sans-serif;
  margin-bottom: 350px;
}
.lessHeight {
  height: 470px;
}

.getStartedbtn :hover {
  background-color: #3644a1 !important;
}

.bluecolor {
  color: #1e266d;
}

/* //priyansh  */
.button Button {
  position: relative;
  bottom: "16rem" !important;
}

.image img {
  width: 23rem;
  /* height: 31.5 rem !important; */
  max-height: 31.5rem;
  min-height: 31.5rem;
  box-shadow: 1px 4px 24px 12px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
}

.arrow {
  /* background-color: #3644a1 !important; */
  position: absolute !important;
  /* cursor: pointer; */
  z-index: 10;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .arrow :hover{
    background-color: aqua;
  } */

.next {
  right: 0.6%;
  top: 46%;
}

.prev {
  left: 0.45%;
  top: 46%;
}

.selecttemplatebtn {
  width: max-content;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;
  /* background-color: #3644a1; */
  border: none;
  font-family: "noto sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  margin-top: -250px;
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.5);
}

.selecttemplatebtn:hover {
  width: max-content;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;
  /* background-color: #3644a1; */
  border: none;
  font-family: "noto sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-top: -250px;
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.9);

 
}

.slick-slider {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
}

.slick-slider .slick-list {
  padding: 70px 0 !important;
}

.slick-slide.slick-active.slick-center.slick-current {
  transform: scale(1.2);
}

.slick-slide {
  transition: 0.3s ease-in-out transform;
}
