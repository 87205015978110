.language-box {
    display: inline-block;
    margin: 5px;
    padding: 10px 20px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .language-box:hover {
    background-color: #007bff;
    color: white;
  }
  
  .languages-selection {
    margin-bottom: 20px;
  }
  .plus-icon {
    font-size: 20px;
    color:#7a8599;;
    font-weight: 700; /* A balanced font weight for readability */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    cursor: pointer; /* Indicates interactivity */
    padding-bottom:5px;
}

.plus-icon:hover {
   color:white;
    transform: scale(1.1); /* Adds a subtle zoom effect on hover */
}