.file-dropzone {
    height: 400px;
    border: 2px dashed var(--gray);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  
  .image-wrapper {
    height: 400px;
    background-color: var(--dark);
    position: relative;
  }
  