.atom__input {
  appearance: none;
  border: none;
  padding: 0.5rem;
  background-color: #f2f5fa;
  font-size: 0.875rem;
  border-radius: 3px;
}

.atom__input--stretch {
  width: 100%;
}

input.atom__input:-webkit-autofill,
input.atom__input:-webkit-autofill:hover,
input.atom__input:-webkit-autofill:focus,
textarea.atom__input:-webkit-autofill,
textarea.atom__input:-webkit-autofill:hover,
textarea.atom__input:-webkit-autofill:focus {
  border: none;
  background-color: #f2f5fa;
  transition: background-color 5000s ease-in-out 0s;
}
