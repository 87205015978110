.allLink {
    font-family: "Noto Sans", sans-serif;
    text-decoration: none;
    color: white;
    width: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    
  }
  
  .allLink:hover {
    font-family: "Noto Sans", sans-serif;
    font-weight: bold;
    color: white;
    width: auto;
  }