/* SevenColumnsRow.css */
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; /* Adjust as needed */
  }
  
  .col {
    flex: 1;
    padding: 0 10px; /* Adjust as needed */
    box-sizing: border-box;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .col {
      flex-basis: 50%; /* Two columns per row on smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .col {
      flex-basis: 100%; /* Full width on extra small screens */
    }
  }
  