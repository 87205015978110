.img-overlay{
    position: absolute; 
    bottom: 10px; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6); /* Black see-through */
    color: #f1f1f1; 
    width: 91%;
    transition: .5s ease;
    opacity:0.8;
    color: white;
    font-size: 20px;
    padding: 15px;
    text-align: center;	
  }
  
  .img-container {
    position: relative;
    width: 50%;
    max-width: 300px;
    transition: transform .5s ease;
    padding:10px;
  }
  
  .img-container:hover .img-overlay {
    opacity: 1;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
    
  }
  
  .img-container:hover {
    transform: scale(1.05);
  }



  
/*AI Card on mobile*/
@media (max-width: 576px) {
  .icon1 {position:absolute; top: 10px; left:50px; width:32px; transform:rotate(-45deg)}
  .icon2 {position:absolute; top:150px; left:30px; width:32px; transform:rotate(45deg)}
  .icon3 {display: none; position:absolute; top:250px; left:80px; width:32px; transform:rotate(50deg)}
  .icon4 {position:absolute; top:380px; left:20px; width:32px; transform:rotate(-65deg)}
  .icon5 {display: none; position:absolute; top:25px; right:75px; width:32px; transform:rotate(-20deg)}
  .icon6 {position:absolute; top:100px; right:10px; width:32px; transform:rotate(35deg)}
  .icon7 {position:absolute; top:275px; right:50px; width:32px; transform:rotate(-75deg)}
  .icon8 {position:absolute; top:500px; right:40px; width:32px; transform:rotate(45deg)}
}

/*AI Card on desktop*/
@media (min-width: 577px) {
  
  .icon1 {position:absolute; top: 40px; left:100px; width:48px; transform:rotate(-45deg)}
  .icon2 {position:absolute; top:150px; left:250px; width:48px; transform:rotate(45deg)}
  .icon3 {position:absolute; top:300px; left:300px; width:48px; transform:rotate(50deg)}
  .icon4 {position:absolute; top:450px; left:100px; width:48px; transform:rotate(-65deg)}
  .icon5 {position:absolute; top:40px; right:150px; width:48px; transform:rotate(-20deg)}
  .icon6 {position:absolute; top:150px; right:250px; width:48px; transform:rotate(35deg)}
  .icon7 {position:absolute; top:300px; right:100px; width:48px; transform:rotate(-75deg)}
  .icon8 {position:absolute; top:450px; right:350px; width:48px; transform:rotate(45deg)}
  
}