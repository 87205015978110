.igBtn-primary {
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.igBtn-link {
  color: var(--blue);
  background-color: transparent;
  border: none;
  padding: 0;
}
