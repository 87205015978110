
.code-container {
    background-color: #f8f9fa;
    border: 1px solid #e1e4e8;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
}
pre {
    background-color: #f8f9fa;
    border: 0;
}
code {
    font-size: 14px;
}
