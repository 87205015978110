
.color-picker {
  width: 60%; /* Adjusted for responsiveness */
  max-width: 1000px; /* Limit maximum width for larger screens */
  margin-left: calc(100% - 40px); /* Calculate margin to keep position */
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 2px; /* Original padding */
  /* background-color: #2C3895; */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

@media (max-width: 1200px) {
.color-picker {
    width: 50%; /* Adjusted width for medium screens */
   
}
}

@media (max-width: 768px) {
.color-picker {
    width: 20%; /* Adjusted width for smaller screens */
    padding: 2.5px; /* Reduced padding for smaller screens */
   
    gap: 1px;
    top:35px;
    margin-left: calc(78% - 200px);
    padding-right: 180px;
 
    
}
}

/* Individual color picker */
.picker {
width: 35px;
height: 35px;
border: 2px solid #FFFFFF; /* White border for contrast */
border-radius: 50%;
background-image: linear-gradient(to right, #e30505, #d9c80d, #09a643);
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
transition: transform 0.1s ease;
}

/* Favorite color container */
#favcolor1 {
border: none;
/* background-color:#2C3895; */
width: 35px;
height: 35px;
opacity: 0;
}

/* Color options container */
.color-options {
display: flex;
justify-content: center;
margin-top: 10px; /* Spacing between color picker and options */
}

/* Individual color option */
.color-option {
width: 35px;
height: 35px;
margin: 0 7px; /* Adjusted margin for spacing between color options */
cursor: pointer;
border-radius: 50%;
transition: transform 0.1s ease; /* Smooth hover effect */
}

.color-option:hover {
transform: scale(1.25); /* Scale up on hover for interactive feel */
}
.picker:hover{
transform: scale(1.25);
}

/* Color picker input */
input[type="color"] {
width: 35px;
height: 35px;
border: none;
cursor: pointer;
}


