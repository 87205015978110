/*Prof Summary AI Card on mobile*/
@media (max-width: 576px) {
    .igbot-ai-suggestion-card {
      position: fixed;
      bottom: 80px; /* Adjust this value to place the card just above the button */
      right: 20px; /* Align the right edge */
      z-index: 99999;
      width: calc(100% - 40px); /* Ensure it doesn't overflow on mobile */
      height: 400px !important;
      border-radius: 3px;
      background: rgb(255, 255, 255);
      box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
      transition: opacity 0.15s ease-out, transform 0.15s ease-out;
      opacity: 1;
    }
  }
  
  /*Prof Summary AI Card on desktop*/
  @media (min-width: 577px) {
    .igbot-ai-suggestion-card {
      position: fixed;
      bottom: 80px; /* Adjust this value to place the card just above the button */
      right: 20px; /* Align the right edge */
      z-index: 99999;
      width: 560px;
      height: 450px !important;
      border-radius: 3px;
      background: rgb(255, 255, 255);
      box-shadow: rgba(23, 68, 130, 0.2) 0px 0px 1px, rgba(23, 68, 130, 0.15) 0px 2px 14px;
      transition: opacity 0.15s ease-out, transform 0.15s ease-out;
      opacity: 1;
    }
  }
  
  .suggestionLine {
    cursor: pointer;
  }
  
  .suggestionLine:hover {
    background-color: var(--geeks-gray-200);
  }
  