.project-card.selected {
    border: 2px solid blue;
    position: relative;
  }
  
  .tick-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 16px;
    color: blue;
  }
  