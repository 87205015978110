.skills-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.skill-box {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
}

.skill-box:hover {
    background-color: #007bff;
    color:white;
}
.plus-icon {
    font-size: 20px;
    color:#7a8599;;
    font-weight: 700; /* A balanced font weight for readability */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    cursor: pointer; /* Indicates interactivity */
    padding-bottom:5px;
}

.plus-icon:hover {
   color:white;
    transform: scale(1.1); /* Adds a subtle zoom effect on hover */
}
